/* Stili personalizzati per il tour */

/* Sovrascrive il testo del pulsante Next */
.react-joyride__tooltip button[data-action="primary"] {
  position: relative;
  color: transparent !important;
}

.react-joyride__tooltip button[data-action="primary"]::after {
  content: "Avanti";
  position: absolute;
  left: 0;
  right: 0;
  color: #fff;
}

/* Sovrascrive il testo del pulsante Last */
.react-joyride__tooltip button[data-action="primary"][data-joyride-element="last-button"] {
  position: relative;
  color: transparent !important;
}

.react-joyride__tooltip button[data-action="primary"][data-joyride-element="last-button"]::after {
  content: "Fine";
  position: absolute;
  left: 0;
  right: 0;
  color: #fff;
}

/* Sovrascrive il testo del pulsante Back */
.react-joyride__tooltip button[data-action="back"] {
  position: relative;
  color: transparent !important;
}

.react-joyride__tooltip button[data-action="back"]::after {
  content: "Indietro";
  position: absolute;
  left: 0;
  right: 0;
  color: #ad0e38;
}

/* Sovrascrive il testo del pulsante Skip */
.react-joyride__tooltip button[data-action="skip"] {
  position: relative;
  color: transparent !important;
}

.react-joyride__tooltip button[data-action="skip"]::after {
  content: "Salta";
  position: absolute;
  left: 0;
  right: 0;
  color: #666;
}

/* Nasconde il testo "Step X of Y" */
.react-joyride__tooltip div[data-test-id="progress"] {
  display: none;
}
