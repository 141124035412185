.politician-swiper {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  overflow: hidden;
  background-color: #1a1a1a !important;
}

.politician-swiper .swiper {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  background-color: #1a1a1a !important;
}

.politician-swiper .swiper-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  display: flex;
  align-items: center !important;
  width: 100% !important;
  background-color: #1a1a1a !important;
}

.politician-swiper .swiper-slide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-position: center;
  background-size: cover;
  width: 100% !important;
  height: 100% !important;
  transition: transform 0.3s ease, opacity 0.3s ease;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  flex-direction: column;
  background-color: #1a1a1a !important;
}

.politician-swiper .swiper-slide:not(.swiper-slide-active) {
  opacity: 0.5;
  transform: scale(0.85);
  background-color: #1a1a1a !important;
}

.politician-swiper .swiper-slide-active {
  opacity: 1;
  transform: scale(1);
  z-index: 10;
  background-color: #1a1a1a !important;
}

/* Search container */
.search-container {
  position: static;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  background-color: #333333;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.search-container .MuiTextField-root {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.search-container .MuiTextField-root:focus-within {
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
}

.search-container .MuiInputBase-root {
  border-radius: 20px;
}

/* Load more button */
.load-more-container {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .politician-swiper .swiper-slide {
    width: 100% !important;
    max-height: calc(100vh - 56px) !important;
  }
}
