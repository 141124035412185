/* Global styles for box sizing and margins */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  overflow-y: auto; /* Consenti lo scroll verticale di default */
}

/* Apply overflow hidden only to user portal */
.user-portal {
  overflow: hidden;
}

/* Explicitly enable scrolling for admin portal */
.admin-portal {
  overflow: visible !important;
}

.admin-portal .MuiBox-root[component="main"] {
  overflow: auto !important;
  max-height: 100vh;
}

/* Override any container restrictions for admin portal */
.admin-portal .MuiContainer-root {
  overflow: visible !important;
  max-height: none !important;
}

/* Remove any potential borders from MUI components */
.MuiPaper-root, .MuiCard-root, .MuiBox-root, .MuiContainer-root {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

/* Ensure content containment only for user portal */
.user-portal .MuiContainer-root {
  max-width: 100vw !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

/* Consenti lo scroll nella landing page */
.landing-page {
  overflow-y: auto !important;
  height: auto !important;
  min-height: 100vh !important;
}
