html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

body {
  background-color: #1a1a1a;
}

.mySwiper {
  width: 100vw !important;
  padding: 0 !important;
  margin: 0 !important;
  height: calc(100vh - 56px) !important;
  overflow: hidden;
  left: 0 !important;
  right: 0 !important;
  background-color: #1a1a1a !important;
}

.swiper {
  width: 100vw !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  left: 0 !important;
  right: 0 !important;
  background-color: #1a1a1a !important;
}

.swiper-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  display: flex;
  align-items: flex-start !important; /* Allinea in alto */
  width: 100vw !important;
  left: 0 !important;
  right: 0 !important;
  background-color: #1a1a1a !important;
}

.swiper-slide {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important; /* Allinea in alto */
  background-position: center;
  background-size: cover;
  width: 100vw !important;
  height: 100% !important;
  transition: transform 0.3s ease, opacity 0.3s ease;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  flex-direction: column;
  left: 0 !important;
  right: 0 !important;
  background-color: #1a1a1a !important;
}

.swiper-slide:not(.swiper-slide-active) {
  opacity: 0.5;
  transform: scale(0.85);
}

.swiper-slide-active {
  opacity: 1;
  transform: scale(1);
  z-index: 10;
}

.swiper-pagination {
  position: absolute !important;
  bottom: 10px !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 10 !important;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  background-color: #007aff;
  opacity: 1;
}

/* Media query per dispositivi mobili */
@media (max-width: 600px) {
  .swiper-slide {
    width: 100vw !important;
    max-height: calc(100vh - 56px) !important;
  }
}
